import React from 'react';
import { Navigation, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

export default function SoftwareDevelopment() {
	
	
	return (
		<>
        
		{/* Banner Start */}
        <div className="section techwix-hero-section-02 pb-0" style={{backgroundImage: "url(images/bg/software-development-banner.png)"}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="hero-content">
                            <h3 className="sub-title" data-aos-delay="600" data-aos="fade-up"> We provide the most advance digital solution</h3>
                            <h2 className="title" data-aos="fade-up" data-aos-delay="800">Leading Software Development Studio & Agency</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-svg">
			<img src="images/bg/shape.svg" alt=""/>
            </div>
        </div>
        {/* Banner End  */}

		{/* Choose Us Start */}
        <div className="section techwix-choose-us-section techwix-choose-us-section-04 section-padding">
            <div className="container">
                <div className="choose-us-wrap">
				    <div className="section-title text-center">
                        <h3 className="sub-title">Software Development</h3>
                        <h2 className="title">Best Software Development Services</h2>
                    </div>
                    <div className="choose-us-content-wrap">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon9.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Custom software development</a></h3>
                                        <p>We make software that's valuable for your business in the long run, designed specifically for your needs and adaptable for the future.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-3.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Cloud application development</a></h3>
                                        <p>We create and provide scalable, portable, and secure cloud applications. Additionally, we help in moving apps of any complexity from on-site infrastructures to the cloud.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon10.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Software support and maintenance</a></h3>
                                        <p>We make sure your software runs smoothly and stays up-to-date by continually monitoring performance, proactively optimizing, resolving issues quickly, and delivering new features and integrations.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon12.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Software consulting</a></h3>
                                        <p>We bring together expertise in multiple domains, client-focused thinking, and an innovative approach to design and create dependable solutions that align perfectly with the needs and behaviours of their users.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-1.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Desktop Application Development</a></h3>
                                        <p>We create desktop applications for Windows, macOS, or Linux operating systems, offering software solutions tailored to your needs.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-2.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Quality Assurance and Testing</a></h3>
                                        <p>We ensure software reliability, security, and performance through testing processes, including unit testing, integration testing, and user acceptance testing.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon21.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Blockchain Development</a></h3>
                                        <p>We develop blockchain applications, including decentralized apps (DApps) and smart contracts, utilizing cutting-edge blockchain technology for innovative solutions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon22.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Artificial Intelligence (AI) and Machine Learning (ML)</a></h3>
                                        <p>We integrate AI and ML algorithms into software for enhanced functionality, task automation, and intelligent solutions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Choose Us End */}

		<div className="section section-padding pt-0">
			<div className="container">
				<div className="service-one">
					<div className="row">
						<div className="col-lg-5 col-md-4">
						<img src="images/home/about-1.jpg" alt=""/>
						</div>
						<div className="col-lg-7 col-md-8">
							<div className="service-content mt-3">
								<div className="section-title">
									<h2 className="title">Software Development</h2>
								</div>
								<p>At Code System, we're ready to offer complete development services, covering everything from analyzing your business needs to delivering and supporting software. Whether you're looking to update old software or need expert advice at any stage of the Software Development Life Cycle (SDLC), we're here for you.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> 

        {/* Case Study Start */}
        <div className="section techwix-case-study-section-02 section-padding" style={{backgroundImage: "url(images/bg/case-study-bg.jpg)"}}>
            <div className="container">
                <div className="case-study-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title">From our Case studies</h3>
                        <h2 className="title white">We delivered best solution</h2>
                    </div>
                </div>
            </div>
            <Swiper
			// install Swiper modules
			modules={[Navigation, EffectCoverflow]} 
				spaceBetween={30}
				// slidesPerView={1}
				loop={true}
				navigation= {true}
				effect={'coverflow'}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={3}
				coverflowEffect={{
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: true,
				}}
		>      
            <div className="case-study-content-wrap">
                <div className="case-study-active">
					<SwiperSlide>
                    <div className="single-case-study-02">
                        <a target='_blank' href="https://www.lifelongindiaonline.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/lifelongindiaonline.jpg" alt="Lifelong India Online"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Built for India</p>
                                <h3 className="title"><span>Lifelong India Online</span></h3>
                                <p className="text">At Lifelong, we build innovative solutions for India. We build contemporary products for you. We rely on the rich manufacturing heritage and aesthetic approach to make these products a part of homes and workspaces across India.</p>
                            </div>
                        </a>  
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                        <a target='_blank' href="https://www.irasva.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/irasva.jpg" alt="irasva"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Fine Jewellery</p>
                                <h3 className="title"><span>Irasva</span></h3>
                                <p className="text">When the purest of emotions, ʻIRAʼ or love, formed an eternal bond with ʻSVAʼ the essence of self; an inseparable harmony of self love called ʻIRASVAʼ was born.</p>
                            </div>
                        </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://www.eatkibikibi.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/eatkibikibi.jpg" alt="eatkibikibi"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Eat Kibi Kibi</p>
                                    <h3 className="title"><span>Bringing back the joy of good food!</span></h3>
                                    <p className="text">We’re strong believers that food isn’t just a means of survival, it’s fuel for the soul. And we shouldn’t have to choose between healthy & tasty.</p>
                                </div>
                            </a>   
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://brassoforge.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/brassoforge.jpg" alt="brassoforge"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Brassoforge</p>
                                    <h3 className="title"><span>Manufacturer & Supplier</span></h3>
                                    <p className="text">An IATF 16949:2016 certified company, Brassoforge® is a leading manufacturer and supplier of forged/machined components and sub-assemblies of Aluminium, Brass & Copper.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://mezmocandy.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/mezmocandy.jpg" alt="mezmocandy"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">mezmocandy</p>
                                    <h3 className="title"><span>I Treat You can Trust</span></h3>
                                    <p className="text">We’ve always loved yummy treats and decided to make our own to share it with the world, just without any of those pesky chemicals or evil additives.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://www.gptuae.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/gptuae.jpg" alt="gptuae"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Gptuae</p>
                                    <h3 className="title"><span>Create your future with us</span></h3>
                                    <p className="text">GPT's broad network and worldwide expertise allow us to understand the clients needs, and supply the best candidates and services to the global market.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>   
                </div>
            </div>
            </Swiper>
        </div>
        {/* Case Study End */}

		<div className="section section-padding" style={{backgroundImage:"url(images/bg/experience-section-bg-img.jpg)"}}>
			<div className="container">
				<div className="service-wrap">
					<div className="service-top">
						<div className="row">
							<div className="col-xl-7 col-lg-7 col-md-8 offset-lg-6 offset-md-5">
								<div className="service-content">
									<div className="section-title">
										<h2 className="title">Why Code System for Software Development</h2>
									</div>
									<p>Choose Code System for software development because we bring expertise, dedication, and a client-focused approach to every project. Our team of skilled developers is committed to delivering top-quality software solutions tailored to your specific needs. At Code System, we prioritize your satisfaction, ensuring effective communication, transparency, and timely delivery. We have a proven track record of successful collaborations with various clients, ranging from small businesses to major global brands. Whether you're in need of web development, mobile app creation, or software enhancements, our versatile services cover it all. With Code System, you can trust that your project is in capable hands, and we are dedicated to bringing your ideas to life with efficiency and excellence.</p>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>   

		

        

		</>
	)
	
}


