import React from 'react';
import { Navigation, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

export default function WebDevelopment() {
	
	
	return (
		<>
        
		{/* Banner Start */}
        <div className="section techwix-hero-section-02 pb-0" style={{backgroundImage: "url(images/hero2-bg.jpg)"}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="hero-content">
                            <h3 className="sub-title" data-aos-delay="600" data-aos="fade-up"> Web Development</h3>
                            <h2 className="title" data-aos="fade-up" data-aos-delay="800">Looking for a Web Development with top-notch services?</h2>
                            <div className="header-btn banner-but mt-4"><a class="btn" href="/contact">Contact us now!</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-svg">
			<img src="images/bg/shape.svg" alt=""/>
            </div>
        </div>
        {/* Banner End  */}

		{/* Choose Us Start */}
        <div className="section techwix-choose-us-section techwix-choose-us-section-04 section-padding">
            <div className="container">
                <div className="choose-us-wrap">
				    <div className="section-title text-center">
                        <h3 className="sub-title">Web Development</h3>
                        <h2 className="title">We are working this</h2>
                    </div>
                    <div className="choose-us-content-wrap">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon9.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Website Development</a></h3>
                                        <p>A good website not only markets and communicates well but also builds trust and brings in potential customers.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-3.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Web Application</a></h3>
                                        <p>Web applications can be seen by people all around the world. They are interactive and interesting, making it easy to keep users interested.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon10.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Ecommerce</a></h3>
                                        <p>With eCommerce solutions, businesses can be open 24x7 support connect with customers in any time zone.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon12.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Content Management System</a></h3>
                                        <p>Our customized CMS will help your business by making internal tasks more efficient, saving both time and money.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-1.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Front-End Development</a></h3>
                                        <p>We offer HTML for web content structure, CSS for styling HTML elements, and JavaScript for client-side interactivity and dynamic content.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-2.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Back-End Development</a></h3>
                                        <p>We offer Back-End Development with server-side languages like Python, JavaScript, PHP, etc. Also, databases like MysQL, MongoDB, and SQLite for data storage & management.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon21.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Frameworks & Libraries</a></h3>
                                        <p>We offer Front-End Frameworks (React.js, Angular, Vue.js) and Back-End Frameworks (Express.js, Django, Ruby on Rails, Flask) for simplified development.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon22.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Testing Frameworks</a></h3>
                                        <p>We provide Testing Frameworks (Jest, Mocha, Jasmine) for code testing and Debugging Tools integrated into browsers or external tools.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Choose Us End */}

		<div className="section section-padding pt-0">
			<div className="container">
				<div className="service-one">
					<div className="row">
						<div className="col-lg-5 col-md-4">
						<img src="images/home/about-1.jpg" alt=""/>
						</div>
						<div className="col-lg-7 col-md-8">
							<div className="service-content mt-3">
								<div className="section-title">
									<h2 className="title">Web Development Services</h2>
								</div>
								<p>At Code System, we're proud to offer excellent web development services. Our team has worked with small businesses and big global brands, successfully meeting the unique needs of each project. </p>
                                    <p>Using our skills and experience, we make sure to deliver exactly what our clients are looking for. Whether it's a simple website or a complex project, we're here to help.</p> 
                                    <p>Our goal is to provide top-notch service that our clients can rely on. So, if you're looking for a team that's dedicated to making your web development project a success, Code System is here for you. Your satisfaction is our priority, and we're excited to bring your ideas to life online.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> 

        {/* Case Study Start */}
        <div className="section techwix-case-study-section-02 section-padding" style={{backgroundImage: "url(images/bg/case-study-bg.jpg)"}}>
            <div className="container">
                <div className="case-study-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title">From our Case studies</h3>
                        <h2 className="title white">We delivered best solution</h2>
                    </div>
                </div>
            </div>
            <Swiper
			// install Swiper modules
			modules={[Navigation, EffectCoverflow]} 
				spaceBetween={30}
				// slidesPerView={1}
				loop={true}
				navigation= {true}
				effect={'coverflow'}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={3}
				coverflowEffect={{
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: true,
				}}
		>      
            <div className="case-study-content-wrap">
                <div className="case-study-active">
					<SwiperSlide>
                    <div className="single-case-study-02">
                        <a target='_blank' href="https://www.lifelongindiaonline.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/lifelongindiaonline.jpg" alt="Lifelong India Online"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Built for India</p>
                                <h3 className="title"><span>Lifelong India Online</span></h3>
                                <p className="text">At Lifelong, we build innovative solutions for India. We build contemporary products for you. We rely on the rich manufacturing heritage and aesthetic approach to make these products a part of homes and workspaces across India.</p>
                            </div>
                        </a>  
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                        <a target='_blank' href="https://www.irasva.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/irasva.jpg" alt="irasva"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Fine Jewellery</p>
                                <h3 className="title"><span>Irasva</span></h3>
                                <p className="text">When the purest of emotions, ʻIRAʼ or love, formed an eternal bond with ʻSVAʼ the essence of self; an inseparable harmony of self love called ʻIRASVAʼ was born.</p>
                            </div>
                        </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://www.eatkibikibi.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/eatkibikibi.jpg" alt="eatkibikibi"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Eat Kibi Kibi</p>
                                    <h3 className="title"><span>Bringing back the joy of good food!</span></h3>
                                    <p className="text">We’re strong believers that food isn’t just a means of survival, it’s fuel for the soul. And we shouldn’t have to choose between healthy & tasty.</p>
                                </div>
                            </a>   
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://brassoforge.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/brassoforge.jpg" alt="brassoforge"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Brassoforge</p>
                                    <h3 className="title"><span>Manufacturer & Supplier</span></h3>
                                    <p className="text">An IATF 16949:2016 certified company, Brassoforge® is a leading manufacturer and supplier of forged/machined components and sub-assemblies of Aluminium, Brass & Copper.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://mezmocandy.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/mezmocandy.jpg" alt="mezmocandy"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">mezmocandy</p>
                                    <h3 className="title"><span>I Treat You can Trust</span></h3>
                                    <p className="text">We’ve always loved yummy treats and decided to make our own to share it with the world, just without any of those pesky chemicals or evil additives.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://www.gptuae.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/gptuae.jpg" alt="gptuae"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Gptuae</p>
                                    <h3 className="title"><span>Create your future with us</span></h3>
                                    <p className="text">GPT's broad network and worldwide expertise allow us to understand the clients needs, and supply the best candidates and services to the global market.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>   
                </div>
            </div>
            </Swiper>
        </div>
        {/* Case Study End */}

		<div className="section section-padding" style={{backgroundImage:"url(images/bg/experience-section-bg-img.jpg)"}}>
			<div className="container">
				<div className="service-wrap">
					<div className="service-top">
						<div className="row">
							<div className="col-xl-7 col-lg-7 col-md-8 offset-lg-6 offset-md-5">
								<div className="service-content">
									<div className="section-title">
										<h2 className="title">Why Code System for Web Development Services?</h2>
									</div>
									<p>we build our teams based on your project's needs. Every team member is carefully selected to add valuable expertise to your product. We're skilled in handling all aspects of web development.</p>
									<div className="about-list">
                                    <ul>
                                        <li>
                                            <span className="about-icon"><i className="fas fa-check"></i></span>
                                            <span className="about-text">Our UI designers create visually attractive user interfaces.</span>
                                        </li>
                                        <li>
                                            <span className="about-icon"><i className="fas fa-check"></i></span>
                                            <span className="about-text">UX designers focus on making app visuals and interactions easy to understand, practical, and engaging for users.</span>
                                        </li>
                                        <li>
                                            <span className="about-icon"><i className="fas fa-check"></i></span>
                                            <span className="about-text">Frontend developers work closely with design and backend teams to deliver responsive, flexible, fast, secure, and user-friendly applications.</span>
                                        </li>
                                    </ul>
                                </div>
                                    <p>With our thoughtful team selection and diverse skills, we ensure your project gets the attention and expertise it deserves. At Code System, we're committed to bringing your ideas to life through well-crafted, user-friendly, and visually appealing web solutions. Your success is our priority.</p>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>   

		

        

		</>
	)
	
}


