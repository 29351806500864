import React from 'react';
import { Navigation, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

export default function FrontEndDevelopment() {
	
	
	return (
		<>
        
		{/* Banner Start */}
        <div className="section techwix-hero-section-02 pb-0" style={{backgroundImage: "url(images/bg/front-development.jpg)"}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="hero-content">
                            <h3 className="sub-title" data-aos-delay="600" data-aos="fade-up">Front End Development </h3>
                            <h2 className="title" data-aos="fade-up" data-aos-delay="800">Get Excellent Front End Development services.</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-svg">
			<img src="images/bg/shape.svg" alt=""/>
            </div>
        </div>
        {/* Banner End  */}

		{/* Choose Us Start */}
        <div className="section techwix-choose-us-section techwix-choose-us-section-04 section-padding">
            <div className="container">
                <div className="choose-us-wrap">
				    <div className="section-title text-center">
                        <h3 className="sub-title">Web Development</h3>
                        <h2 className="title">We are working this</h2>
                    </div>
                    <div className="choose-us-content-wrap">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon9.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Front-End Frameworks</a></h3>
                                        <p>We use front-end frameworks like React.js (from Facebook), Angular (from Google), and Vue.js for building user interfaces.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-3.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">UI/UX Design</a></h3>
                                        <p>We offer UI/UX design, working with designers to craft user interfaces that are intuitive and visually appealing.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon10.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Front-End Testing</a></h3>
                                        <p>We conduct front-end testing, including unit and end-to-end testing, to ensure the functionality and reliability of the code.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon12.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Performance Optimization</a></h3>
                                        <p>We optimize web page performance to reduce loading times and improve user experience.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-1.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">JavaScript</a></h3>
                                        <p>We use JavaScript for client-side scripting, making web pages interactive and dynamic. It's employed for tasks like form validation and animations.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-2.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Build Tools and Task Runners</a></h3>
                                        <p>We use build tools like Webpack, Gulp, or Grunt to automate tasks, optimize assets, and simplify the development process.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon21.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Continuous Integration</a></h3>
                                        <p>We implement Continuous Integration (CI) with CI/CD pipelines to automate testing and deploying changes in front-end code.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon22.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Front-End Testing</a></h3>
                                        <p>We conduct front-end testing, including unit and end-to-end testing, to ensure the functionality and reliability of the code.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Choose Us End */}

		<div className="section section-padding pt-0">
			<div className="container">
				<div className="service-one">
					<div className="row">
						<div className="col-lg-5 col-md-4">
						<img src="images/home/about-1.jpg" alt=""/>
						</div>
						<div className="col-lg-7 col-md-8">
							<div className="service-content mt-3">
								<div className="section-title">
									<h2 className="title">Front End Development Services</h2>
								</div>
								<p>At Code System, we offer top-notch Front-End Development Services to make your websites and applications visually appealing, user-friendly, and highly functional. Our skilled team focuses on creating the face of your digital presence, ensuring seamless navigation and an engaging user experience. We specialize in using popular frameworks like React.js, Angular, and Vue.js to build interactive and responsive interfaces. Whether you need a website revamp or a new application, our Front-End Development Services cater to diverse needs. We prioritize user interface (UI) and user experience (UX) design, making sure your digital platforms not only look good but also provide a smooth and enjoyable journey for your users. Trust us to bring your ideas to life with creativity, efficiency, and a commitment to delivering front-end solutions that meet and exceed your expectations.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> 

        {/* Case Study Start */}
        <div className="section techwix-case-study-section-02 section-padding" style={{backgroundImage: "url(images/bg/case-study-bg.jpg)"}}>
            <div className="container">
                <div className="case-study-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title">From our Case studies</h3>
                        <h2 className="title white">We delivered best solution</h2>
                    </div>
                </div>
            </div>
            <Swiper
			// install Swiper modules
			modules={[Navigation, EffectCoverflow]} 
				spaceBetween={30}
				// slidesPerView={1}
				loop={true}
				navigation= {true}
				effect={'coverflow'}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={3}
				coverflowEffect={{
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: true,
				}}
		>      
            <div className="case-study-content-wrap">
                <div className="case-study-active">
					<SwiperSlide>
                    <div className="single-case-study-02">
                        <a target='_blank' href="https://www.lifelongindiaonline.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/lifelongindiaonline.jpg" alt="Lifelong India Online"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Built for India</p>
                                <h3 className="title"><span>Lifelong India Online</span></h3>
                                <p className="text">At Lifelong, we build innovative solutions for India. We build contemporary products for you. We rely on the rich manufacturing heritage and aesthetic approach to make these products a part of homes and workspaces across India.</p>
                            </div>
                        </a>  
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                        <a target='_blank' href="https://www.irasva.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/irasva.jpg" alt="irasva"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Fine Jewellery</p>
                                <h3 className="title"><span>Irasva</span></h3>
                                <p className="text">When the purest of emotions, ʻIRAʼ or love, formed an eternal bond with ʻSVAʼ the essence of self; an inseparable harmony of self love called ʻIRASVAʼ was born.</p>
                            </div>
                        </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://www.eatkibikibi.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/eatkibikibi.jpg" alt="eatkibikibi"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Eat Kibi Kibi</p>
                                    <h3 className="title"><span>Bringing back the joy of good food!</span></h3>
                                    <p className="text">We’re strong believers that food isn’t just a means of survival, it’s fuel for the soul. And we shouldn’t have to choose between healthy & tasty.</p>
                                </div>
                            </a>   
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://brassoforge.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/brassoforge.jpg" alt="brassoforge"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Brassoforge</p>
                                    <h3 className="title"><span>Manufacturer & Supplier</span></h3>
                                    <p className="text">An IATF 16949:2016 certified company, Brassoforge® is a leading manufacturer and supplier of forged/machined components and sub-assemblies of Aluminium, Brass & Copper.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://mezmocandy.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/mezmocandy.jpg" alt="mezmocandy"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">mezmocandy</p>
                                    <h3 className="title"><span>I Treat You can Trust</span></h3>
                                    <p className="text">We’ve always loved yummy treats and decided to make our own to share it with the world, just without any of those pesky chemicals or evil additives.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://www.gptuae.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/gptuae.jpg" alt="gptuae"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Gptuae</p>
                                    <h3 className="title"><span>Create your future with us</span></h3>
                                    <p className="text">GPT's broad network and worldwide expertise allow us to understand the clients needs, and supply the best candidates and services to the global market.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>   
                </div>
            </div>
            </Swiper>
        </div>
        {/* Case Study End */}

		<div className="section section-padding" style={{backgroundImage:"url(images/bg/experience-section-bg-img.jpg)"}}>
			<div className="container">
				<div className="service-wrap">
					<div className="service-top">
						<div className="row">
							<div className="col-xl-7 col-lg-7 col-md-8 offset-lg-6 offset-md-5">
								<div className="service-content">
									<div className="section-title">
										<h2 className="title">Why Code System for Front End Development Services</h2>
									</div>
									<p>Choose Code System for Front-End Development Services because we're committed to creating visually stunning and user-friendly websites and applications. Our team specializes in using popular frameworks like React.js, Angular, and Vue.js to craft interactive and responsive interfaces. We prioritize user interface (UI) and user experience (UX) design, ensuring your digital platforms not only look good but also provide a smooth and enjoyable experience for users. With a focus on creativity and efficiency, we bring your ideas to life, delivering front-end solutions that meet and exceed expectations. Trust Code System to be your partner in building an impressive and user-centric online presence.</p>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>   

		

        

		</>
	)
	
}


