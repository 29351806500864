import React from 'react'
import { Link } from 'react-router-dom'
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


export default function Home() {
  return (
    
    <>
      <div className="main-wrapper">
        {/* Banner Start */}
        <div className="section techwix-hero-section" style={{backgroundImage: "url(images/home/home-banner.jpg)"}}>
          <div className="shape-2"></div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="hero-content">
                  <h3 className="sub-title" data-aos-delay="600" data-aos="fade-up"><span>HOT !</span> Get Managed Digital Services</h3>
                  <h2 className="title" data-aos="fade-up" data-aos-delay="800">Software Development and Consulting</h2>
                  <p data-aos="fade-up" data-aos-delay="900"> We help businesses by providing strong and flexible digital solutions that meet today's needs and open up opportunities for the future.</p>
                  {/* <div className="hero-btn" data-aos="fade-up" data-aos-delay="1000">
                      <a className="btn" href="">Read More</a>
                  </div> */}
                </div> 
              </div>
              {/* <div className="col-lg-7"> 
                <div className="hero-images">
                  <div className="images">
                      <img src="images/home/hero-img1.png" alt=""/>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* Banner End */}

        {/* Service Start */}
        <div className="section techwix-service-section section-padding">
            <div className="container">
                <div className="service-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title">What we provide</h3>
                        <h2 className="title">Get one of the best Software Development Services</h2>
                    </div>
                    <div className="service-content-wrap">
                        <div className="row">
                            <div className="col-xl-3 col-md-6">
                                <div className="service-item service-01">
                                    <div className="service-img">
                                        <img src="images/home/ser-icon1.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="service.html">Software development</a></h3>
                                        <p>With 05 years of expertise, we craft reliable, scalable, and secure software across OS, browsers, and devices. Our team blends industry wisdom with cutting-edge tech for personalized solutions that align seamlessly with user needs.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="service-item">
                                    <div className="service-img">
                                        <img src="images/home/ser-icon2.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="service.html">Application services</a></h3>
                                        <p>Our team helps small, medium and large companies in creating, testing, securing, managing, moving, and improving digital solutions for cost-effective and reliable performance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="service-item service-03">
                                    <div className="service-img">
                                        <img src="images/home/ser-icon3.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="service.html">Testing & QA</a></h3>
                                        <p>We Offer QA and testing services, we assist in QA development, TCoE setup, and evolution. Our rigorous testing spans mobile, web, and desktop apps throughout all development phases.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="service-item">
                                    <div className="service-img">
                                        <img src="images/home/ser-icon4.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="service.html">IT consulting</a></h3>
                                        <p>Our experts help in creating a successful IT strategy, ensuring smooth digital transformation and system integration, and providing advice on enhancing your digital customer experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Service End */}

       
         {/* About Start */}
        <div className="section techwix-about-section section-padding" style={{backgroundImage: "url(images/about-bg.png)"}}>
            <div className="shape-1"></div>
            <div className="container">
                <div className="about-wrap">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-img-wrap">
                                <div className="about-img about-img-big">
                                    <img src="images/home/about-1.jpg" alt=""/>
                                </div>
                                <div className="about-img about-img-sm">
                                    <img src="images/home/about-2.jpg" alt=""/>
                                    <div className="shape-01"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content-wrap">
                                <div className="section-title">
                                    <h3 className="sub-title">Who we are</h3>
                                    <h2 className="title">Enhance and innovate using the latest tech trends.</h2>
                                </div>
                                <p>Code System, a leading tech partner, delivers innovative solutions for businesses. Our skilled team excels in web and mobile app development, ensuring clients thrive in the digital landscape. Services include front-end/back-end development, eCommerce solutions, and custom software. We use the latest tech and best practices to create robust, scalable, user-centric apps. Our commitment to quality, creativity, and client satisfaction sets us apart. Whether you're a startup, SME, or enterprise, Code System tailors solutions to meet your unique needs. We prioritize clear communication, transparency, and efficiency. Trust Code System to transform your ideas into reality, delivering cutting-edge tech solutions that propel your business forward. Your success is our mission.</p>
                                {/* <div className="play-btn">
                                    <a className="popup-video" href="https://www.youtube.com/watch?time_continue=3&amp;v=_X0eYtY8T_U"><i className="fas fa-play"></i> <span>How we work</span></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* About End */}

         {/* Cta Start */}
         <div className="section techwix-cta-section section-padding">
            <div className="container">
                <div className="cta-wrap">
                    <div className="cta-icon">
                        <img src="images/home/cta-icon1.png" alt=""/>
                    </div>
                    <div className="cta-content text-center">
                        <p>Let's chat and figure out a better way together. <Link to='/contact'>Let’s talk with us</Link></p>
                    </div>
                </div>
            </div>
        </div>
        {/* Cta End */}


        {/* About Start */}
        {/* <div className="section techwix-about-section-02 section-padding">
            <div className="container">
                <div className="about-wrap">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-02-left text-center">
                                <div className="section-title">
                                    <h2 className="title">You know you did right when all your effort started to pay off in an unexpected and impressive way.</h2>
                                </div>
                                <div className="about-author">
                                    <img src="images/home/sign.png" alt=""/>
                                    <h3 className="name">Alen Morno sin</h3>
                                    <span className="designation">CEO, Techmax</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-02-right">
                                <p>To boost productivity and reduce project costs for our customers, we invest a portion of our revenue in:</p>
                                <div className="about-list">
                                    <ul>
                                        <li>
                                            <span className="about-icon"><i className="fas fa-check"></i></span>
                                            <span className="about-text">Ensuring high quality and optimizing processes. </span>
                                        </li>
                                        <li>
                                            <span className="about-icon"><i className="fas fa-check"></i></span>
                                            <span className="about-text"> Embracing new technologies. </span>
                                        </li>
                                        <li>
                                            <span className="about-icon"><i className="fas fa-check"></i></span>
                                            <span className="about-text">Training and ongoing education for employees </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* About End */}

        {/* Counter Start */}
        {/* <div className="section techwix-counter-section">
            <div className="container">
                <div className="counter-wrap">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="single-counter">
                                <div className="counter-img">
                                    <img src="images/home/counter-1.png" alt=""/>
                                </div>
                                <div className="counter-content">
                                    <span className="counter">1790</span>
                                    <p>Happy clients</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="single-counter">
                                <div className="counter-img">
                                    <img src="images/home/counter-2.png" alt=""/>
                                </div>
                                <div className="counter-content">
                                    <span className="counter">491</span>
                                    <p>Finished projects</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="single-counter">
                                <div className="counter-img">
                                    <img src="images/home/counter-3.png" alt=""/>
                                </div>
                                <div className="counter-content">
                                    <span className="counter">245</span>
                                    <p>Skilled Experts</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="single-counter">
                                <div className="counter-img">
                                    <img src="images/home/counter-1.png" alt=""/>
                                </div>
                                <div className="counter-content">
                                    <span className="counter">1090</span>
                                    <p>Media Posts</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* Counter End */}

        {/* Testimonial Start */}
        {/* <div className="section bg-cover techwix-testimonial-section-02 techwix-testimonial-section-03 section-padding" style={{backgroundImage: "url(images/bg/testi-bg.jpg)"}}>
            <div className="container">
                    <div className="section-title text-center">
                        <h3 className="sub-title">Testimonial</h3>
                        <h2 className="title">Satisfied clients worldwide</h2>
                    </div>
                <Swiper
                    
                    modules={[Navigation, Pagination]} 
                    spaceBetween={100}
                    loop={true}
                    navigation= {false}
                    pagination={{ clickable: true }}
                    slidesPerView={2}
                    breakpoints= {{
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 50,
                        },
                        991: {
                            slidesPerView: 2,
                            spaceBetween: 100,
                        }
                    }}
                    
                > 
                <SwiperSlide>
                    <div className="single-testimonial-02 one">
                        <div className="testimonial-content">
                            <img src="images/home/testi-icon.png" alt=""/>
                            <p>We appreciate their proactive approach and ability to suggest improvements to a prospective solution on both architectural and business levels. We know we can always rely on Code System various competencies when our clients require quality software which would facilitate their business success.</p>
                            <div className="testimonial-thumb">
                            <img src="images/home/user.png" alt=""/>
                             </div>
                            <span className="name">Mike Holder </span>
                            <span className="designation"> / CEO, Harlond inc</span>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="single-testimonial-02">
                        <div className="testimonial-content">
                            <img src="images/home/testi-icon.png" alt=""/>
                            <p>To bring out the exceptional user experience of the speakers through a mobile app, we were looking for a tech company that understood our needs and suggested recommendations on top of that to create a clean mobile app. We exactly got that.</p>
                            <div className="testimonial-thumb">
                                <img src="images/home/user.png" alt=""/>
                            </div>
                            <span className="name">Matthew Rosen </span>
                            <span className="designation"> / Business Development Manager</span>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="single-testimonial-02">
                        <div className="testimonial-content">
                            <img src="images/home/testi-icon.png" alt=""/>
                            <p>I hired Code System to stress test an online cloud platform that needed to prove both stability and accuracy. The team at Code System is professional and organized in every way possible. </p>
                            <div className="testimonial-thumb">
                                <img src="images/home/user.png" alt=""/>
                            </div>
                            <span className="name">Hendrik Riemeier </span>
                            <span className="designation"> / Managing director</span>
                        </div>
                    </div>
                </SwiperSlide>
                </Swiper>
            </div>
        </div> */}
        {/* Testimonial End  */}

        {/* Brand Logo Start */}
        {/* <div className="section techwix-brand-section">
          <div className="container">
            <div className="brand-wrapper text-center">
              <h3 className="brand-title">Step forward to become one of <span>49,494</span> successful clients of Mitech </h3>
              <div className="brand-active">
                <ul>
                  <li><img src="images/brand/brand-1.png" alt="Brand"/></li>
                  <li><img src="images/brand/brand-2.png" alt="Brand"/></li>
                  <li><img src="images/brand/brand-3.png" alt="Brand"/></li>
                  <li><img src="images/brand/brand-4.png" alt="Brand"/></li>
                  <li><img src="images/brand/brand-5.png" alt="Brand"/></li>
                  <li><img src="images/brand/brand-2.png" alt="Brand"/></li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
        {/* Brand Logo End */}

      </div>
 
    </>
  )
}
// export default Home;
