import React from 'react'
import { Link } from 'react-router-dom'
export default function Footer() {
  return (
    <>
        
		<div className="section footer-section" style={{backgroundImage: "url(images/bg/footer-bg.jpg)"}}>
			<div className="container">
				<div className="footer-widget-wrap">
					<div className="row">
						<div className="col-lg-3 col-sm-6">
							<div className="footer-widget-about">
								<a className="footer-logo" href="index.html"><img src="images/logo-white.png" alt="Logo"/></a>
								<p>Code System, a leading software development services provider in India, boasts over a decade of experience team in pioneering engineering solutions across various industries.</p>
								
							</div>
						</div>
						<div className="col-lg-3 col-sm-6">
							<div className="footer-widget">
								<h4 className="footer-widget-title">Useful Links</h4>

								<div className="widget-link">
									<ul className="link">
										<li><Link to='/'>Home</Link></li>
										<li><Link to='/about'>About Company</Link></li>
										<li><Link to='/CaseStudies'>Case Studies</Link></li>
										<li><Link to='/contact'>Contact US</Link></li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-sm-6">
							<div className="footer-widget">
								<h4 className="footer-widget-title">Our Services</h4>

								<div className="widget-link">
									<ul className="link">
										<li><Link to='/WebDevelopment'>Web Development</Link></li>
										<li><Link to='/MobileAppDevelopment'>Mobile App Development</Link></li>
										<li><Link to='/SoftwareDevelopment'>Software Development</Link></li>
										<li><Link to='/FrontEndDevelopment'>Front end Development</Link></li>
										{/* <li><Link to='/BackEndDevelopment'>Back end Development</Link></li>
										<li><Link to='/ECommerceDevelopment'>eCommerce Development</Link></li> */}
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-sm-6">
							<div className="footer-widget">
								<h4 className="footer-widget-title">Contact Information</h4>

								<div className="widget-info">
									<ul>
										<li>
											<div className="info-icon">
												<i className="flaticon-phone-call"></i>
											</div>
											<div className="info-text">
												<span><a href="tel:+919702830971">+91 970 283 0971</a></span>
											</div>
										</li>
										<li>
											<div className="info-icon">
												<i className="far fa-envelope-open"></i>
											</div>
											<div className="info-text">
												<span><a href="mailto:info@codesystem.com">info@codesystem.com</a></span>
											</div>
										</li>
										<li>
											<div className="info-icon">
												<i className="flaticon-pin"></i>
											</div>
											<div className="info-text">
												<span><a target='_blank' href='https://www.google.com/maps/place/Regimental/@19.9530535,73.8365284,19z/data=!3m1!4b1!4m6!3m5!1s0x3bdd958b6fd7e5e9:0x4f982ea2f76d676b!8m2!3d19.9530522!4d73.8371721!16s%2Fg%2F11h1z1lfwg?entry=ttu'> S-12, Regimental Plaza, Gaikwad Mala, Bitco Point, Nashik Road, Maharashtra, India 422101</a></span>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="footer-copyright-area">
				<div className="container">
					<div className="footer-copyright-wrap">
						<div className="row">
							<div className="col-lg-6">
								<div className="copyright-text">
									<p>© Copyrights 2024 Code System All rights reserved. </p>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="footer-social text-end">
									<ul className="social">
										<li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
										<li><a href="#"><i className="fab fa-twitter"></i></a></li>
										<li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
									</ul>
								</div>
							</div>	
						</div>
					</div>
				</div>
			</div>
		</div>

		{/* back to top start */}
        <div className="progress-wrap">
            <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
            </svg>
        </div>
         {/* back to top end  */}
			
    </>
  )
}
