import React, { useState } from 'react'

export default function Contact() {

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: ''
  })

  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({
        ...formData, [name] : value
    })
    
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const validationErrors = {}
    if(!formData.firstname.trim()) {
        validationErrors.firstname = "firstname is required"
    }

    if(!formData.lastname.trim()) {
      validationErrors.lastname = "lastname is required"
  }

    if(!formData.email.trim()) {
        validationErrors.email = "email is required"
    } else if(!/\S+@\S+\.\S+/.test(formData.email)){
        validationErrors.email = "email is not valid"
    }

    // if(!formData.phone.trim()) {
    //     validationErrors.phone = "phone is required"
    // } else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(formData.phone)){
    //     validationErrors.phone = "Please provide valid phone number"
    // }

	var phone = '1234567890';
	if( !formData.phone.trim(phone.match('[0-9]{10}')) ){
		alert('Please provide valid phone number');
	}else if(formData.phone.length < 10){
        validationErrors.phone = "phone number should be at least 10 char"
    }

	if(!formData.message.trim()) {
		validationErrors.message = "message is required"
	}


    setErrors(validationErrors)

    if(Object.keys(validationErrors).length === 0) {
        alert("Form Submitted successfully");
   
    }
	 console.log(formData);

    
  }


  return (
    <>
        {/* Page Banner Start */}
        <div className="section page-banner-section breadcrumb-section" style={{backgroundImage: "url(images/page-banner.jpg)"}}>
            <div className="container">
                <div className="page-banner-wrap">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-banner text-center">
                                <h2 className="title">Contact Us</h2>
                                <ul className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Page Banner End */}


		{/* Contact Start  */}
        <div className="section techwix-contact-section techwix-contact-section-02 techwix-contact-section-03 section-padding-02">
            <div className="container">
                <div className="contact-wrap">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="contact-form">
                                <div className="contact-form-wrap">
                                    <div className="heading-wrap text-center">
                                        <span className="sub-title"> request a quote</span>
                                        <h3 className="title">How May We Help You!</h3>
                                    </div>
                                    <form onSubmit={handleSubmit} className="mry-form mry-mb-100">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="single-form">
												<input type="text" name="firstname" placeholder='First Name' autoComplete='off' onChange={handleChange} className="mry-default-link" />
                          						{errors.firstname && <span>{errors.firstname}</span>} 
                                                </div>
                                            </div>
											<div className="col-sm-6">
                                                <div className="single-form">
												<input type="text" name="lastname" placeholder="lastname" className="mry-default-link" autoComplete='off' onChange={handleChange} />
                      							{errors.lastname && <span>{errors.lastname}</span>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="single-form">
												<input type="email" name="email" placeholder="example@gmail.com" className="mry-default-link" autoComplete='off' onChange={handleChange} />
                        						{errors.email && <span>{errors.email}</span>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="single-form">
												<input type="number" name="phone" placeholder="+0 (000) 000 00 00" className="mry-default-link" autoComplete='off' onChange={handleChange} />
                      							{errors.phone && <span>{errors.phone}</span>}  
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="single-form">
												<textarea id="message" name="message" rows="8" cols="80" placeholder="Type your message here" className="mry-default-link" type="text"
												data-parsley-pattern="^[a-zA-Z0-9\s.:,!?']+$" required></textarea>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-btn">
                                                    <button className="btn" type="submit">Send Message</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Contact End  */}
        
        {/* Contact Info Start */}
        <div className="section contact-info-section section-padding">
            <div className="container">
                <div className="contact-info-wrap">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-contact-info text-center">
                                <div className="info-icon">
                                    <img src="images/info-1.png" alt=""/>
                                </div>
                                <div className="info-content">
                                    <h5 className="title">Give us a call</h5>
                                    <p><a href="tel:+919702830971">+91 970 283 0971</a></p>
                                    <p><a href="tel:+919619653189">+91 961 965 3189</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-contact-info text-center">
                                <div className="info-icon">
                                    <img src="images/info-2.png" alt=""/>
                                </div>
                                <div className="info-content">
                                    <h5 className="title">Drop us a line</h5>
                                    <p><a href="mailto:info@codesystem.com">info@codesystem.com</a></p>
                                    {/* <p><a href="mailto:manoj@codesystem.com">manoj@codesystem.com</a></p> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-contact-info text-center">
                                <div className="info-icon">
                                    <img src="images/info-3.png" alt=""/>
                                </div>
                                <div className="info-content">
                                    <h5 className="title">Visit our office</h5>
                                    <p><a target='_blank' href='https://www.google.com/maps/place/Regimental/@19.9530535,73.8365284,19z/data=!3m1!4b1!4m6!3m5!1s0x3bdd958b6fd7e5e9:0x4f982ea2f76d676b!8m2!3d19.9530522!4d73.8371721!16s%2Fg%2F11h1z1lfwg?entry=ttu'> S-12, Regimental Plaza, Gaikwad Mala, Bitco Point, Nashik Road, Maharashtra, India 422101</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         {/* Contact Info End  */}

    </>
  )
}
