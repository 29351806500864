import React from 'react';
import { Navigation, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

export default function BackEndDevelopment() {
	
	
	return (
		<>
        
		{/* Banner Start */}
        <div className="section techwix-hero-section-02 pb-0" style={{backgroundImage: "url(images/bg/back-development.jpg)"}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="hero-content">
                            <h3 className="sub-title" data-aos-delay="600" data-aos="fade-up">Back End Development</h3>
                            <h2 className="title" data-aos="fade-up" data-aos-delay="800">Leading Back End Development services</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-svg">
			<img src="images/bg/shape.svg" alt=""/>
            </div>
        </div>
        {/* Banner End  */}

		{/* Choose Us Start */}
        <div className="section techwix-choose-us-section techwix-choose-us-section-04 section-padding">
            <div className="container">
                <div className="choose-us-wrap">
				    <div className="section-title text-center">
                        <h3 className="sub-title">Web Development</h3>
                        <h2 className="title">We are working this</h2>
                    </div>
                    <div className="choose-us-content-wrap">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon9.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Server-Side Programming Languages</a></h3>
                                        <p>We use server-side programming languages like Node.js (JavaScript), Python (Django or Flask), Ruby (Ruby on Rails), Java (Spring), & PHP Laravel.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-3.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Back-End Frameworks</a></h3>
                                        <p>We use Back-End Frameworks to streamline development, offering structure and pre-built modules for efficient server-side programming solutions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon10.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Databases</a></h3>
                                        <p>We offer database services, designing and managing databases like MySQL, PostgreSQL, Microsoft SQL Server (relational) and MongoDB, Cassandra, Redis (NoSQL).</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon12.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Server Management</a></h3>
                                        <p>We offer server management, configuring and managing web servers like Nginx or Apache for tasks such as serving files.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-1.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Version Control</a></h3>
                                        <p>We offer version control using Git to track code changes and facilitate collaboration among developers.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-2.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Security</a></h3>
                                        <p>We implement security measures to protect against web vulnerabilities, including SQL injection, cross-site scripting (XSS), and cross-site request forgery (CSRF).</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon21.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Scalability and application</a></h3>
                                        <p>We ensure scalability by designing architecture to handle increased loads and making sure the application scales effectively.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon22.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Testing</a></h3>
                                        <p>We perform unit, integration, and system testing to ensure the reliability and functionality of the back-end code.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Choose Us End */}

		<div className="section section-padding pt-0">
			<div className="container">
				<div className="service-one">
					<div className="row">
						<div className="col-lg-5 col-md-4">
						<img src="images/home/about-1.jpg" alt=""/>
						</div>
						<div className="col-lg-7 col-md-8">
							<div className="service-content mt-3">
								<div className="section-title">
									<h2 className="title">Back End Development Services</h2>
								</div>
								<p>Code System specializes in Back-End Development, ensuring your website or application runs smoothly behind the scenes.</p> 
                                <p>Our skilled team focuses on the server-side, handling tasks like database management, server configuration, and application logic. We use languages such as Node.js, Python, Ruby, Java, and PHP to build robust back-end systems.</p>
                                <p> Whether you need to optimize performance, enhance security, or scale your platform, we've got the expertise. Our Back-End Development services include crafting APIs, managing databases, and implementing server-side logic to ensure your front-end and user interface function seamlessly.</p>
                                <p> From setting up servers to handling data storage, we cover it all, providing reliable and efficient back-end solutions. Trust Code System to empower your digital presence with expert Back-End Development services tailored to your unique requirements. 
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> 

        {/* Case Study Start */}
        <div className="section techwix-case-study-section-02 section-padding" style={{backgroundImage: "url(images/bg/case-study-bg.jpg)"}}>
            <div className="container">
                <div className="case-study-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title">From our Case studies</h3>
                        <h2 className="title white">We delivered best solution</h2>
                    </div>
                </div>
            </div>
            <Swiper
			// install Swiper modules
			modules={[Navigation, EffectCoverflow]} 
				spaceBetween={30}
				// slidesPerView={1}
				loop={true}
				navigation= {true}
				effect={'coverflow'}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={3}
				coverflowEffect={{
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: true,
				}}
		>      
            <div className="case-study-content-wrap">
                <div className="case-study-active">
					<SwiperSlide>
                    <div className="single-case-study-02">
                        <a target='_blank' href="https://www.lifelongindiaonline.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/lifelongindiaonline.jpg" alt="Lifelong India Online"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Built for India</p>
                                <h3 className="title"><span>Lifelong India Online</span></h3>
                                <p className="text">At Lifelong, we build innovative solutions for India. We build contemporary products for you. We rely on the rich manufacturing heritage and aesthetic approach to make these products a part of homes and workspaces across India.</p>
                            </div>
                        </a>  
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                        <a target='_blank' href="https://www.irasva.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/irasva.jpg" alt="irasva"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Fine Jewellery</p>
                                <h3 className="title"><span>Irasva</span></h3>
                                <p className="text">When the purest of emotions, ʻIRAʼ or love, formed an eternal bond with ʻSVAʼ the essence of self; an inseparable harmony of self love called ʻIRASVAʼ was born.</p>
                            </div>
                        </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://www.eatkibikibi.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/eatkibikibi.jpg" alt="eatkibikibi"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Eat Kibi Kibi</p>
                                    <h3 className="title"><span>Bringing back the joy of good food!</span></h3>
                                    <p className="text">We’re strong believers that food isn’t just a means of survival, it’s fuel for the soul. And we shouldn’t have to choose between healthy & tasty.</p>
                                </div>
                            </a>   
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://brassoforge.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/brassoforge.jpg" alt="brassoforge"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Brassoforge</p>
                                    <h3 className="title"><span>Manufacturer & Supplier</span></h3>
                                    <p className="text">An IATF 16949:2016 certified company, Brassoforge® is a leading manufacturer and supplier of forged/machined components and sub-assemblies of Aluminium, Brass & Copper.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://mezmocandy.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/mezmocandy.jpg" alt="mezmocandy"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">mezmocandy</p>
                                    <h3 className="title"><span>I Treat You can Trust</span></h3>
                                    <p className="text">We’ve always loved yummy treats and decided to make our own to share it with the world, just without any of those pesky chemicals or evil additives.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://www.gptuae.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/gptuae.jpg" alt="gptuae"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Gptuae</p>
                                    <h3 className="title"><span>Create your future with us</span></h3>
                                    <p className="text">GPT's broad network and worldwide expertise allow us to understand the clients needs, and supply the best candidates and services to the global market.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>   
                </div>
            </div>
            </Swiper>
        </div>
        {/* Case Study End */}

		<div className="section section-padding" style={{backgroundImage:"url(images/bg/experience-section-bg-img.jpg)"}}>
			<div className="container">
				<div className="service-wrap">
					<div className="service-top">
						<div className="row">
							<div className="col-xl-7 col-lg-7 col-md-8 offset-lg-6 offset-md-5">
								<div className="service-content">
									<div className="section-title">
										<h2 className="title">Why Code System Back-End Developement</h2>
									</div>
									<p>Choose Code System for Back-End Development as we excel in creating powerful, efficient, and secure server-side solutions that elevate your website or application. Our dedicated team of developers brings expertise in languages like Node.js, Python, Ruby, Java, and PHP, ensuring robust back-end systems that handle data management, server configuration, and application logic seamlessly. With a focus on performance optimization, security enhancement, and scalability, we provide solutions tailored to meet the specific needs of your project.</p>
                                    <p> Whether you're setting up servers, crafting APIs, or managing databases, our comprehensive Back-End Development services cover it all. Trust Code System to be your reliable partner in building a strong foundation for your digital platform, ensuring it runs smoothly and efficiently for a seamless user experience.</p>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>   

		

        

		</>
	)
	
}


