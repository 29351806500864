import React from 'react';
import { Navigation, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

export default function ECommerceDevelopment() {
	
	
	return (
		<>
        
		{/* Banner Start */}
        <div className="section techwix-hero-section-02 pb-0" style={{backgroundImage: "url(images/bg/ecommerce-development.jpg)"}}>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-7">
                        <div className="hero-content">
                            <h3 className="sub-title" data-aos-delay="600" data-aos="fade-up">E-Commerce Development </h3>
                            <h2 className="title" data-aos="fade-up" data-aos-delay="800">Get High Quality eCommerce Development services.</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-svg">
			<img src="images/bg/shape.svg" alt=""/>
            </div>
        </div>
        {/* Banner End  */}

		{/* Choose Us Start */}
        <div className="section techwix-choose-us-section techwix-choose-us-section-04 section-padding">
            <div className="container">
                <div className="choose-us-wrap">
				    <div className="section-title text-center">
                        <h3 className="sub-title">Web Development</h3>
                        <h2 className="title">We are working this</h2>
                    </div>
                    <div className="choose-us-content-wrap">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon9.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Online Store Development</a></h3>
                                        <p>We design and develop eCommerce websites, creating user-friendly interfaces and intuitive navigation for an optimal online shopping experience.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-3.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">eCommerce Platforms</a></h3>
                                        <p>We use popular eCommerce platforms like Magento, Shopify, WooCommerce, and BigCommerce, each offering unique features and customization options for online stores.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon10.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">eCommerce Solutions</a></h3>
                                        <p>We offer custom eCommerce solutions, crafting tailored online platforms to meet the specific needs and requirements of your business.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon12.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Cart Development</a></h3>
                                        <p>We develop secure and user-friendly shopping cart systems to ensure smooth and secure transactions on your eCommerce website.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-1.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Catalog Management</a></h3>
                                        <p>We create and manage product catalogs, incorporating features like categories, tags, and product variants for efficient catalog management.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/counter-2.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Security Measures</a></h3>
                                        <p>We implement security measures, including SSL certificates and PCI DSS compliance, to safeguard customer data and ensure secure transactions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon21.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Analytics and Reporting</a></h3>
                                        <p>We integrate analytics tools to track and analyze user behavior, sales performance, and other crucial metrics for informed decision-making.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/icons/ser-icon22.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Reviews and Ratings</a></h3>
                                        <p>We include customer reviews and ratings features to foster trust and help customers in making informed purchasing decisions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Choose Us End */}

		<div className="section section-padding pt-0">
			<div className="container">
				<div className="service-one">
					<div className="row">
						<div className="col-lg-5 col-md-4">
						<img src="images/home/about-1.jpg" alt=""/>
						</div>
						<div className="col-lg-7 col-md-8">
							<div className="service-content mt-3">
								<div className="section-title">
									<h2 className="title">eCommerce Development services </h2>
								</div>
								<p>At Code System, our eCommerce Development services are tailored to create online stores that stand out. We design and develop user-friendly eCommerce websites with intuitive interfaces, ensuring a seamless shopping experience.</p>
                                <p> Utilizing popular platforms like Magento, Shopify, WooCommerce, and BigCommerce, we provide versatile solutions to meet diverse business needs. Whether you need a fully customized eCommerce platform or a user-friendly shopping cart system, we've got you covered.</p>
                                <p> Our services extend to catalog management, implementing product variants, categories, and tags for efficient management. We prioritize security, implementing measures like SSL certificates and PCI DSS compliance to protect customer data and facilitate secure transactions. With features such as analytics, reporting, and reviews, we empower your eCommerce site for success, fostering customer trust and informed decision-making. Trust Code System to bring your eCommerce ideas to life, creating a digital storefront that resonates with your audience.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> 

        {/* Case Study Start */}
        <div className="section techwix-case-study-section-02 section-padding" style={{backgroundImage: "url(images/bg/case-study-bg.jpg)"}}>
            <div className="container">
                <div className="case-study-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title">From our Case studies</h3>
                        <h2 className="title white">We delivered best solution</h2>
                    </div>
                </div>
            </div>
            <Swiper
			// install Swiper modules
			modules={[Navigation, EffectCoverflow]} 
				spaceBetween={30}
				// slidesPerView={1}
				loop={true}
				navigation= {true}
				effect={'coverflow'}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={3}
				coverflowEffect={{
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: true,
				}}
		>      
            <div className="case-study-content-wrap">
                <div className="case-study-active">
					<SwiperSlide>
                    <div className="single-case-study-02">
                        <a target='_blank' href="https://www.lifelongindiaonline.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/lifelongindiaonline.jpg" alt="Lifelong India Online"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Built for India</p>
                                <h3 className="title"><span>Lifelong India Online</span></h3>
                                <p className="text">At Lifelong, we build innovative solutions for India. We build contemporary products for you. We rely on the rich manufacturing heritage and aesthetic approach to make these products a part of homes and workspaces across India.</p>
                            </div>
                        </a>  
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                        <a target='_blank' href="https://www.irasva.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/irasva.jpg" alt="irasva"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Fine Jewellery</p>
                                <h3 className="title"><span>Irasva</span></h3>
                                <p className="text">When the purest of emotions, ʻIRAʼ or love, formed an eternal bond with ʻSVAʼ the essence of self; an inseparable harmony of self love called ʻIRASVAʼ was born.</p>
                            </div>
                        </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://www.eatkibikibi.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/eatkibikibi.jpg" alt="eatkibikibi"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Eat Kibi Kibi</p>
                                    <h3 className="title"><span>Bringing back the joy of good food!</span></h3>
                                    <p className="text">We’re strong believers that food isn’t just a means of survival, it’s fuel for the soul. And we shouldn’t have to choose between healthy & tasty.</p>
                                </div>
                            </a>   
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://brassoforge.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/brassoforge.jpg" alt="brassoforge"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Brassoforge</p>
                                    <h3 className="title"><span>Manufacturer & Supplier</span></h3>
                                    <p className="text">An IATF 16949:2016 certified company, Brassoforge® is a leading manufacturer and supplier of forged/machined components and sub-assemblies of Aluminium, Brass & Copper.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://mezmocandy.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/mezmocandy.jpg" alt="mezmocandy"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">mezmocandy</p>
                                    <h3 className="title"><span>I Treat You can Trust</span></h3>
                                    <p className="text">We’ve always loved yummy treats and decided to make our own to share it with the world, just without any of those pesky chemicals or evil additives.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="single-case-study-02">
                            <a target='_blank' href="https://www.gptuae.com/">
                                <div className="case-study-img">
                                    <img src="images/casestudies/gptuae.jpg" alt="gptuae"/>
                                </div>
                                <div className="case-study-content">
                                    <p className="sub-title">Gptuae</p>
                                    <h3 className="title"><span>Create your future with us</span></h3>
                                    <p className="text">GPT's broad network and worldwide expertise allow us to understand the clients needs, and supply the best candidates and services to the global market.</p>
                                </div>
                            </a>
                        </div>
                    </SwiperSlide>   
                </div>
            </div>
            </Swiper>
        </div>
        {/* Case Study End */}

		<div className="section section-padding" style={{backgroundImage:"url(images/bg/experience-section-bg-img.jpg)"}}>
			<div className="container">
				<div className="service-wrap">
					<div className="service-top">
						<div className="row">
							<div className="col-xl-7 col-lg-7 col-md-8 offset-lg-6 offset-md-5">
								<div className="service-content">
									<div className="section-title">
										<h2 className="title">Why Code System for eCommerce Development services?</h2>
									</div>
									<p>Choose Code System for eCommerce Development services because we excel in creating online stores that stand out and drive success.</p>
                                    <p> Our team is dedicated to designing and developing user-friendly eCommerce websites with intuitive interfaces, ensuring a seamless and enjoyable shopping experience for your customers. We leverage popular eCommerce platforms like Magento, Shopify, WooCommerce, and BigCommerce, tailoring solutions to meet the unique needs of your business.</p>
                                    <p> Whether you require custom eCommerce solutions, a robust shopping cart system, or efficient catalog management, we have the expertise to deliver. Security is our priority, and we implement measures like SSL certificates and PCI DSS compliance to protect customer data and facilitate secure transactions. With features such as analytics, reporting, and customer reviews, we empower your eCommerce site for growth and customer trust. Trust Code System to be your reliable partner in creating a successful and impactful online presence for your business.</p>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>   

		

        

		</>
	)
	
}


