import React from 'react'


export default function About() {

      return (
    <>
    
        {/* Page Banner Start */}
        <div className="section page-banner-section breadcrumb-section" style={{backgroundImage: "url(images/page-banner.jpg)"}}>
            <div className="container">
                <div className="page-banner text-center">
                    <h2 className="title">About Us</h2>
                    <ul className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">About Us</li>
                    </ul>
                </div>
            </div>
        </div>
        {/* Page Banner End */}

        {/* Who we are Start */}
        <div className="section techwix-about-section-07 section-padding">
            <div className="shape-1"></div>
            <div className="container">
                <div className="about-wrap">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-img-wrap">
                                <img className="shape-1" src="images/bg/about-shape2.png" alt=""/>
                                <div className="about-img">
                                    <img src="images/about/about-3.jpg" alt=""/>
                                </div>
                                <div className="about-img about-img-2">
                                    <img src="images/about/about-4.jpg" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content-wrap">
                                <div className="section-title">
                                    {/* <h3 className="sub-title">Who we are</h3> */}
                                    <h2 className="title">Who we are</h2>
                                </div>
                                <p className="text">Code System, a leading software development services provider in India, boasts over a decade of experience team in pioneering engineering solutions across various industries.</p>
                                <p> Established in 2018 by technology enthusiasts, of the Owner Manoj Naphade, the company has tirelessly worked to create strategic solutions that have significantly impacted a diverse range of AI and automation platforms for businesses. With a proven track record of successful clients. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Who we are End  */}

        {/* Our Vision Start */}
        <div className="section techwix-about-section-06 section-padding-03">
            <div className="shape-1"></div>
            <div className="container">
                <div className="about-wrap">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="about-content-wrap">
                                <div className="section-title">
                                    {/* <h3 className="sub-title color-3">Our Vision</h3> */}
                                    <h2 className="title">Our Vision</h2>
                                </div>
                                <p className="text">Our core mission revolves around the fundamental belief that technology should simplify and improve how businesses function. We are dedicated to excellence, specialize in delivering exceptional software development services tailored to the specific needs of our clients. Our primary goal is to provide high-quality, customized software solutions that optimize processes, enhance efficiency, and significantly contribute to the success of the businesses we serve.</p>
                                <p>Furthermore, we are dedicated to being a guiding force in the ever-evolving digital landscape. By staying ahead in technological advancements, our aim is to address current challenges but also to equip our clients with insights and tools for future preparedness. We are continuous learning, collaboration, and creativity are ingrained in our commitment to ensuring that our clients consistently receive the finest service.</p>
                                <p>Our mission goes beyond providing services – we look to establish enduring partnerships with our clients, gaining an intimate understanding of their goals and challenges. As more than just a software development provider, we aspire to be a trusted ally on our clients' journeys to success in an increasingly digital world. Our focus centers on making technology accessible, practical, and impactful for businesses of all sizes, ultimately contributing to their sustained growth and prosperity.</p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="about-img">
                                <img className="shape-1" src="images/bg/about-shape1.png" alt=""/>
                                <div className="image">
                                    <img src="images/about/about-img3.jpg" alt=""/>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Our Vision End */}

        {/* Our Mission Start */}
        <div className="section techwix-about-section-06 section-padding-03">
            <div className="shape-1"></div>
            <div className="container">
                <div className="about-wrap">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="about-img">
                                <img className="shape-1" src="images/bg/about-shape1.png" alt=""/>
                                <div className="image">
                                    <img src="images/about/about-img3.jpg" alt=""/>
                                </div>
                               
                            </div>
                        </div>
                    <div className="col-lg-6 offset-lg-1">
                            <div className="about-content">
                                <div className="section-title">
                                    {/* <h3 className="sub-title color-3">Our Mission</h3> */}
                                    <h2 className="title">Our Mission</h2>
                                </div>
                                <p className="text">Accelerate innovation with world-class tech teams We’ll match you to an entire remote team of incredible freelance talent for all your software development needs.</p>
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
        {/* Our Mission End */}

        <div className="section techwix-service-section-06 section-padding" style={{backgroundImage: "url(images/bg/service-bg6.jpg)"}}>
            <div className="container">
                <div className="service-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title color-3">Expertise and Technological Proficiency</h3>
                        <h2 className="title">OUR EXPERTISE</h2>
                    </div>
                    <div className="service-content-wrap">
                        <div className="row">
                            <div className="col-xl-3 col-sm-6">
                                <div className="service-item-05">
                                    <div className="service-img">
                                        <img src="images/about/ser-icon17.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="service.html">Web Development</a></h3>
                                        <p>Code System recruits skilled PHP developers for fast, effective web applications, armed with dedicated resources ensuring quality service.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="service-item-05">
                                    <div className="service-img">
                                        <img src="images/about/ser-icon18.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="service.html">Mobile Development</a></h3>
                                        <p>Code System excels in standardized Mobile app development, emerging as a leading developer with extensive global clients.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="service-item-05">
                                    <div className="service-img">
                                        <img src="images/about/ser-icon19.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="service.html">Software Development</a></h3>
                                        <p>We design, develop, and maintain unique business applications. We learn deeper insights into software-related products.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="service-item-05">
                                    <div className="service-img">
                                        <img src="images/about/ser-icon20.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="service.html">Back End Development</a></h3>
                                        <p>We create code enabling communication between databases and applications. Our developers manage website infrastructure, databases, servers, and apps.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

         {/* Brand Logo Start */}
         <div className="section techwix-brand-section">
          <div className="container">
            <div className="brand-wrapper text-center">
              <h3 className="brand-title">Step forward to become one of <span>49,494</span> successful clients of Mitech </h3>
              <div className="brand-active">
                <ul>
                  <li><img src="images/brand/brand-1.png" alt="Brand"/></li>
                  <li><img src="images/brand/brand-2.png" alt="Brand"/></li>
                  <li><img src="images/brand/brand-3.png" alt="Brand"/></li>
                  <li><img src="images/brand/brand-4.png" alt="Brand"/></li>
                  <li><img src="images/brand/brand-5.png" alt="Brand"/></li>
                  <li><img src="images/brand/brand-2.png" alt="Brand"/></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Brand Logo End */}

		{/* Cta Start */}
        <div className="section techwix-cta-section-02 pt-4">
            <div className="container">
                <div className="cta-wrap" style={{backgroundImage: "url(images/bg/cta-bg.jpg)"}}>
                    <div className="row align-items-center">
                        <div className="col-xl-9 col-lg-8">
                            <div className="cta-content">
                                <div className="cta-icon">
                                    <img src="images/cta-icon2.png" alt=""/>
                                </div>
                                <p>We’re Delivering the best customer Experience</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4">
                            <div className="cta-btn">
                                <a className="btn btn-white" href="tel:+919702830971">+91 970 283 0971</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Cta End */}
			
    </>
  )
}
