import React, { useState } from 'react'

export default function CaseStudies() {

  return (
    <>
        {/* Page Banner Start */}
        <div className="section page-banner-section breadcrumb-section" style={{backgroundImage: "url(images/page-banner.jpg)"}}>
            <div className="container">
                <div className="page-banner-wrap">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-banner text-center">
                                <h2 className="title">Case Studies</h2>
                                <ul className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Case Studies</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Page Banner End */}

           {/* Case Study Start */}
           <div className="section techwix-case-study-section-02 section-padding case-study-page" style={{backgroundImage: "url(images/bg/choose-us-bg.jpg)"}}>
            <div className="container">
                <div className="case-study-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title">From our Case studies</h3>
                        <h2 className="title">We delivered best solution</h2>
                    </div>
                </div>
            </div>
              
            <div className="container-fluid case-study-content-wrap">
                <div className="row case-study-active">
                <div className="col-lg-4 single-case-study-02">
                        <a target='_blank' href="https://play.google.com/store/apps/details?id=com.codesystem.pashumitra&pcampaignid=web_share">
                            <div className="case-study-img">
                                <img src="images/casestudies/pashu-mitra.jpg" alt="pashu-mitra"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Pashumitra</p>
                                <h3 className="title"><span>We Are Providing Pashumitra Service</span></h3>
                                <p className="text">Pashumitra app developed for Farmers, Livestock Owner, Pet Owner, Dairy Farms, Sheep & Goat Farms, Pig Farms, Stud Farms and Veterinary and Farm Consultant.</p>
                            </div>
                        </a>    
                    </div>
                    <div className="col-lg-4 single-case-study-02">
                        <a target='_blank' href="https://www.lifelongindiaonline.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/lifelongindiaonline.jpg" alt="Lifelong India Online"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Built for India</p>
                                <h3 className="title"><span>Lifelong India Online</span></h3>
                                <p className="text">At Lifelong, we build innovative solutions for India. We build contemporary products for you. We rely on the rich manufacturing heritage and aesthetic approach to make these products a part of homes and workspaces across India.</p>
                            </div>
                        </a>    
                    </div>
                    <div className="col-lg-4 single-case-study-02">
                        <a target='_blank' href="https://www.irasva.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/irasva.jpg" alt="irasva"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Fine Jewellery</p>
                                <h3 className="title"><span>Irasva</span></h3>
                                <p className="text">When the purest of emotions, ʻIRAʼ or love, formed an eternal bond with ʻSVAʼ the essence of self; an inseparable harmony of self love called ʻIRASVAʼ was born.</p>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-4 single-case-study-02">
                        <a target='_blank' href="https://www.eatkibikibi.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/eatkibikibi.jpg" alt="eatkibikibi"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Eat Kibi Kibi</p>
                                <h3 className="title"><span>Bringing back the joy of good food!</span></h3>
                                <p className="text">We’re strong believers that food isn’t just a means of survival, it’s fuel for the soul. And we shouldn’t have to choose between healthy & tasty.</p>
                            </div>
                        </a>    
                    </div>
                    <div className="col-lg-4 single-case-study-02">
                        <a target='_blank' href="https://brassoforge.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/brassoforge.jpg" alt="brassoforge"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Brassoforge</p>
                                <h3 className="title"><span>Manufacturer & Supplier</span></h3>
                                <p className="text">An IATF 16949:2016 certified company, Brassoforge® is a leading manufacturer and supplier of forged/machined components and sub-assemblies of Aluminium, Brass & Copper.</p>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-4 single-case-study-02">
                        <a target='_blank' href="https://mezmocandy.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/mezmocandy.jpg" alt="mezmocandy"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">mezmocandy</p>
                                <h3 className="title"><span>I Treat You can Trust</span></h3>
                                <p className="text">We’ve always loved yummy treats and decided to make our own to share it with the world, just without any of those pesky chemicals or evil additives.</p>
                            </div>
                        </a>
                    </div>
                     <div className="col-lg-4 single-case-study-02">
                        <a target='_blank' href="https://www.gptuae.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/gptuae.jpg" alt="gptuae"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Gptuae</p>
                                <h3 className="title"><span>Create your future with us</span></h3>
                                <p className="text">GPT's broad network and worldwide expertise allow us to understand the clients needs, and supply the best candidates and services to the global market.</p>
                            </div>
                        </a>
                    </div>
                     <div className="col-lg-4 single-case-study-02">
                        <a target='_blank' href="https://www.lemontreehotels.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/lemontreehotels.jpg" alt="lemontreehotels"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Lemon Tree Hotels</p>
                                <h3 className="title"><span>Wherever you want to go?</span></h3>
                                <p className="text">We operate in the upscale segment and in the mid-market sector, consisting of the upper-midscale, midscale and economy segments.</p>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-4 single-case-study-02">
                        <a target='_blank' href="https://mezmocandy.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/macksearplugs.jpg" alt="macksearplugs"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Macks Ear Plugs</p>
                                <h3 className="title"><span>USA’s #1 Selling Brand</span></h3>
                                <p className="text">Mckeon Products, Inc., makers of Mack’s® brand products, has committed to supplying the highest quality, innovative hearing protection, sleep aids, and swimmer’s ear prevention products at the best possible value. </p>
                            </div>
                        </a>
                    </div>
                     <div className="col-lg-4 single-case-study-02">
                        <a target='_blank' href="https://mangia24.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/mangia24.jpg" alt="mangia24"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Mangia24</p>
                                <h3 className="title"><span>The Best Food Delivery</span></h3>
                                <p className="text">Now you can make food happen pretty much wherever you are thanks to the free easy-to-use Mangia24 Food Delivery & Takeout App for desktop and mobile.</p>
                            </div>
                        </a>
                    </div>
                     <div className="col-lg-4 single-case-study-02">
                        <a target='_blank' href="https://www.bdsgrill.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/bdsgrill.jpg" alt="bdsgrill"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">BDs Grill</p>
                                <h3 className="title"><span>Bringing you the Classics!</span></h3>
                                <p className="text">BD’s Mongolian Grill has been an innovative Asian-fusion bowl restaurant since 1992. BD’s is the original create-your-own stir fry restaurant, letting you choose what goes into your bowl! </p>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-4 single-case-study-02">
                        <a target='_blank' href="https://www.ittisa.com/">
                            <div className="case-study-img">
                                <img src="images/casestudies/ittisa.jpg" alt="ittisa"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">ittisa</p>
                                <h3 className="title"><span>Digital Marketing Services</span></h3>
                                <p className="text">Wielding the ever-evolving global digital landscape to build brands and drive business growth.</p>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-4 single-case-study-02">
                        <a target='_blank' href="https://www.hamdard.in/">
                            <div className="case-study-img">
                                <img src="images/casestudies/hamdard.jpg" alt="hamdard"/>
                            </div>
                            <div className="case-study-content">
                                <p className="sub-title">Hamdard</p>
                                <h3 className="title"><span>Cure With Unani Care</span></h3>
                                <p className="text">Doctors for any health related concerns over video call. from the comfort of your home</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            
        </div>
        {/* Case Study End */}

    </>
  )
}
