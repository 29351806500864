// import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import About from './components/About';
import CaseStudies from './components/CaseStudies';
import WebDevelopment from './components/WebDevelopment';
import MobileAppDevelopment from './components/MobileAppDevelopment';
import SoftwareDevelopment from './components/SoftwareDevelopment';
import FrontEndDevelopment from './components/FrontEndDevelopment';
import BackEndDevelopment from './components/BackEndDevelopment';
import ECommerceDevelopment from './components/ECommerceDevelopment';
import Contact from './components/Contact';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
    <div className="mry-app">
    <Router>
      <Navbar />
    
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/Contact" element={<Contact/>} />
        <Route exact path="/About" element={<About/>} />
        <Route exact path="/WebDevelopment" element={<WebDevelopment/>} />
        <Route exact path="/MobileAppDevelopment" element={<MobileAppDevelopment/>} />
        <Route exact path="/SoftwareDevelopment" element={<SoftwareDevelopment/>} />
        <Route exact path="/FrontEndDevelopment" element={<FrontEndDevelopment/>} />
        <Route exact path="/BackEndDevelopment" element={<BackEndDevelopment/>} />
        <Route exact path="/ECommerceDevelopment" element={<ECommerceDevelopment/>} />
        <Route exact path="/CaseStudies" element={<CaseStudies/>} />
        
      </Routes>
      <Footer />


    </Router>
     
    </div>
   
    </>
  );
}

export default App;
