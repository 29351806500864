import logo from '../logo.png';
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, NavLink } from 'react-router-dom'
// import {useState} from 'react';

export default function Navbar(props) {
		// Menu open
	  const [isMenuActive, setIsMenuActive] = useState(false);
		const handleMenuClick = () => {
			setIsMenuActive(prevState => !prevState);
		};
		// Menu open End
		


  return (
			<>

			<div id="header" className="section header-section">
				<div className={`mry-menu ${isMenuActive ? 'active-menu' : ''}`}>
					<div className="container">
						<div className="header-wrap">	
							<div className="header-logo">
								<a href='/'><img src={logo} alt=""/></a>
							</div>
							<div className="header-menu">
							<div className="offcanvas-header d-lg-none">
								<div className="offcanvas-logo">
									<a href="index.html"><img src="images/logo-white.png" alt=""/></a>
								</div>
								<button type="button" className={`close-btn mry-magnetic-link ${isMenuActive ? 'mry-active' : ''}`} onClick={handleMenuClick} data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"><i className="flaticon-close"></i></button>
							</div>
								<ul className="main-menu">
									<li><NavLink to='/' activeclassname="active">{props.home}</NavLink></li>
									<li><NavLink to='/about' activeclassname="active">{props.about}</NavLink></li>
									<li><NavLink to='Services' activeclassname="active">{props.Services}</NavLink><i className="fas fa-angle-down"></i>
										<ul className="sub-menu">
											<li><NavLink to='/WebDevelopment' activeclassname="active">{props.WebDevelopment}</NavLink></li>
											<li><NavLink to='/MobileAppDevelopment' activeclassname="active">{props.MobileAppDevelopment}</NavLink></li>
											<li><NavLink to='/SoftwareDevelopment' activeclassname="active">{props.SoftwareDevelopment}</NavLink></li>
											<li><NavLink to='/FrontEndDevelopment' activeclassname="active">{props.FrontEndDevelopment}</NavLink></li>
											<li><NavLink to='/BackEndDevelopment' activeclassname="active">{props.BackEndDevelopment}</NavLink></li>
											<li><NavLink to='/ECommerceDevelopment' activeclassname="active">{props.eCommerceDevelopment}</NavLink></li>
										</ul>
									</li>
									<li><NavLink to='/CaseStudies' activeclassname="active">{props.CaseStudies}</NavLink></li>
									<li><NavLink to='/contact' activeclassname="active">{props.contact}</NavLink></li>
								</ul>
							</div>
							<div className="header-meta">
								<div className="header-btn d-none d-xl-block">
									<Link className="btn" to='/contact'>Get Started</Link>
								</div>
								
								<div className="header-toggle d-lg-none">
									<button className={`mry-magnetic-link ${isMenuActive ? 'mry-active' : ''}`} onClick={handleMenuClick} data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample">
										<span></span>
										<span></span>
										<span></span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="offcanvas-backdrop"></div>
				</div>
			</div>
			
			</> 
    
  		)
  
  
}

Navbar.prototype = {
    home: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired
}

Navbar.defaultProps = {
    home: 'Home',
    about: 'About US',
    Services: 'Services',
    WebDevelopment: 'Web Development',
    MobileAppDevelopment: 'Mobile App Development',
    SoftwareDevelopment: 'Software Development',
    FrontEndDevelopment: 'Front end Development',
    BackEndDevelopment: 'Back end Development',
    eCommerceDevelopment: 'eCommerce Development',
    contact: 'contact Us',
    CaseStudies: 'Case Studies'
    
}